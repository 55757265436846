export interface ValueProposition {
  id: number
  image: string
  title: string
  text: string
}

// Note: This ensures that there are always 9 value propositions,
//       Is there a better way to do this?
type ValuePropositions = [
  ValueProposition,
  ValueProposition,
  ValueProposition,
  ValueProposition,
  ValueProposition,
  ValueProposition,
  ValueProposition,
  ValueProposition,
  ValueProposition,
]

export default function useValueProposition() {
  const { locale } = useI18n()

  const data = [
    {
      id: 1,
      image:
        'https://cdn.jagofon.com/mockdata/value-propositions/quality-guaranteed.png',
      title_en: 'Quality Guaranteed',
      text_en: '100% functional phones.<br>Free 30-day warranty.',
      title_id: 'Jaminan Kualitas',
      text_id: 'Ponsel berfungsi 100%.<br>Garansi 30 hari gratis.',
    },
    {
      id: 2,
      image:
        'https://cdn.jagofon.com/mockdata/value-propositions/affordability.png',
      title_en: 'Affordability',
      text_en:
        'Serious about money? Our fine<br>selection and prices will delight you.',
      title_id: 'Keterjangkauan harga',
      text_id:
        'Pilihan produk kami berkualitas<br>tinggi dengan harga yang terjangkau.',
    },
    {
      id: 3,
      image:
        'https://cdn.jagofon.com/mockdata/value-propositions/transparency.png',
      title_en: 'Transparency & Legitimacy',
      text_en:
        'All phones are original and comply<br>with the latest regulations.',
      title_id: 'Transparansi & Legalitas',
      text_id: 'Semua ponsel original dan<br>tunduk pada peraturan terbaru.',
    },
    {
      id: 4,
      image:
        'https://cdn.jagofon.com/mockdata/value-propositions/customer-service.png',
      title_en: 'Customer Care',
      text_en: 'We always give you the best<br>shopping experience.',
      title_id: 'Layanan Pelanggan',
      text_id: 'Kami selalu memberikan pengalaman<br>belanja terbaik untukmu.',
    },
    {
      id: 5,
      image:
        'https://cdn.jagofon.com/mockdata/value-propositions/free-shipping.png',
      title_en: 'Convenience',
      text_en: 'Buy anytime, anywhere<br>+ Free delivery',
      title_id: 'Kenyamanan',
      text_id: 'Beli kapan saja, di mana saja<br>+ Pengiriman gratis',
    },
    {
      id: 6,
      image:
        'https://cdn.jagofon.com/mockdata/value-propositions/change-the-world.png',
      title_en: 'Change the World',
      text_en: 'One less lonely phone<br>at the landfills.',
      title_id: 'Mengubah Dunia',
      text_id:
        'Berkurang lagi satu ponsel yang<br>tercampakkan di tempat pembuangan.',
    },
    {
      id: 7,
      image:
        'https://cdn.jagofon.com/mockdata/value-propositions/money-back-guarantee.png',
      title_en: '7-day money-back guarantee',
      text_en:
        "You have 7 days to test your new device<br>and decide if it's for you",
      title_id: 'Jaminan 7 hari uang kembali',
      text_id: 'Uji perangkat barumu dan putuskan apakah itu memang untukmu',
    },
    {
      id: 8,
      image:
        'https://cdn.jagofon.com/mockdata/value-propositions/1-year-warranty.png',
      title_en: 'Flexible warranties',
      text_en: 'Our experts will fix your phone<br>when needed',
      title_id: 'Garansi fleksibel',
      text_id: 'Para ahli kami akan memperbaiki ponselmu bila diperlukan',
    },
    {
      id: 9,
      image:
        'https://cdn.jagofon.com/mockdata/value-propositions/free-shipping.png',
      title_en: 'Free shipping on all products',
      text_en: 'We know you love free shipping,<br>so we give it to you',
      title_id: 'Pengiriman gratis untuk semua produk',
      text_id:
        'Kami paham kalau kamu suka dengan yang namanya pengiriman gratis',
    },
  ]

  return data.map<ValueProposition>((item) => ({
    id: item.id,
    image: item.image,
    title: locale.value === 'en' ? item.title_en : item.title_id,
    text: locale.value === 'en' ? item.text_en : item.text_id,
  })) as ValuePropositions
}
