<script lang="ts" setup>
// TODO: Remove tailwind-merge and use variants
import { twMerge } from 'tailwind-merge'
import type { ValueProposition } from '~/composables/useValueProposition'

// TODO: Improve reusability by providing variants instead of passing ui props

interface Props {
  values: ValueProposition[]
  ui?: {
    container?: string
    item?: {
      base?: string
      image?: string
      text?: string
    }
  }
  hideText?: boolean
}

const props = defineProps<Props>()

const ui = computed(() => {
  return {
    container: twMerge(
      'grid grid-cols-1 md:grid-cols-4 gap-3',
      props.ui?.container,
    ),
    item: {
      base: twMerge('text-center', props.ui?.item?.base),
      image: twMerge('', props.ui?.item?.image),
      text: twMerge('mt-2', props.ui?.item?.text),
    },
  }
})
</script>

<template>
  <div :class="ui?.container">
    <div
      v-for="{ id, image, title, text } in props.values"
      :key="id"
      :class="ui.item.base"
    >
      <figure :class="ui.item.image">
        <NuxtPicture
          :src="image"
          :alt="title"
          :img-attrs="{ class: 'mx-auto block h-16 object-contain' }"
          sizes="80px"
        />
      </figure>
      <div :class="ui.item.text">
        <p class="font-semibold text-primary-500">{{ title }}</p>
        <p v-if="!hideText" v-html="text" />
      </div>
    </div>
  </div>
</template>
